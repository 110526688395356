<template>
  <v-row>
    <v-col
      v-if="this.$store.state.session.loggedIn"
      cols="12"
      md="12"
    >
      <v-card class="mt-2">
        <v-row class="ma-0 pa-0">
          <v-col
            v-if="!markMany"
            cols="2"
            style="max-width: 200px;margin-bottom: 0"
          >
            <v-btn
              class="mt-2"
              color="primary"
              style="margin-bottom: 0;padding-bottom: 0"
              @click="editItem(null)"
            >
              + DODAJ SPRZĘT
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="mt-2"
              clearable
              dense
              outlined
              placeholder="Wyszukaj..."
              style="margin-bottom: 0;padding-bottom: 0"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-autocomplete
              v-model="selectedStatus"
              :items="session.statuses"
              class="mt-2"
              clearable
              dense
              item-text="name"
              item-value="id"
              label="Status"
              no-data-text="Nie znaleziono statusu"
              outlined
              multiple
              small-chips
              style="margin-bottom: 0;padding-bottom: 0"
              @change="filterDevices()"
            >
              <template #selection="{ item }">
                <v-chip
                  small
                  :color="item.color"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="2"
            md="2"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-autocomplete
              v-model="selectedCategory"
              :items="session.categories"
              item-text="name"
              item-value="id"
              class="mt-2"
              clearable
              dense
              no-data-text="Nie znaleziono kategorii"
              label="Kategoria"
              outlined
              style="margin-bottom: 0;padding-bottom: 0"
              @change="filterDevices"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            md="1"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-switch
              v-model="markMany"
              dense
              label="Zaznacz wiele"
              style="margin: 5px;padding-bottom: 0"
            >
            </v-switch>
          </v-col>
          <v-col
            cols="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-btn
              v-if="markMany"
              color="primary"
              outlined
              style="margin-bottom: 0;padding-bottom: 0;margin-left: 35px;margin-top:10px"
              @click="exportHardware"
            >
              <v-icon size="20">
                {{ icons.mdiExportVariant }}
              </v-icon>
              EKSPORT
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-data-table
          v-model="selected"
          fixed-header
          :headers="helpers.headers"
          :items="devices"
          :show-select="markMany"
          :loading="dtLoading"
          item-key="id"
          :search="search"
          :items-per-page="devices.length"
          no-results-text="Nie znaleziono urządzeń"
          class="table-rounded"
          hide-default-footer
          height="65vh"
          no-data-text="Nie znaleziono urządzeń"
        >
          <template #[`item.name`]="{item}">
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
              <small>{{ item.serialNo }}</small>
            </div>
          </template>
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="item.color"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.issueDate`]="{item}">
            <span>{{ dateformat(item.issueDate) }}</span>
          </template>
          <template #[`item.actions`]="{item}">
            <v-btn
              :class="item.actions"
              icon
              @click="editItem(item.id)"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
            <v-btn
              color="error"
              :class="item.actions"
              icon
              @click="removeItem(item.id)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-btn
              icon
              :class="item.actions"
              @click="generateProtocolDialog(item.id)"
            >
              <v-icon color="primary">{{icons.mdiFileCheckOutline}}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-dialog v-model="removeDialog"  width="600" persistent>
      <v-card>
        <v-card-title>
            Czy chcesz usunąć sprzęt?
        </v-card-title>
        <v-card-text class="text-center" style="margin-top: 20px;margin-bottom: 20px">
          {{ deviceToRemove }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmRemove">
            TAK
          </v-btn>
          <v-btn color="error" outlined @click="cancelRemove">
            Anuluj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="protocolDialog"  width="600" persistent>
      <v-card>
        <v-card-title>
            Generowanie protokołu
        </v-card-title>
        <v-card-text class="text-center" style="margin-top: 5px;margin-bottom: 5px">
          <v-radio-group
            v-model="protocolType"
            row
          >
            <template v-slot:label>
              <span>Rodzaj protokołu:</span>
            </template>
            <v-radio
              label="Wydanie"
              dense
              value="onboarding"
            ></v-radio>
            <v-radio
              label="Zwrot"
              value="offboarding"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmGenerateProtocol">
            Wygeneruj
          </v-btn>
          <v-btn color="error" outlined @click="cancelGenerateProtocol">
            Anuluj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      persistent
      width="800"
    >
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-subtitle>
          <span
            v-if="valid"
            class="error--text"
          >
            Uzupełnij wymagane pola</span>
        </v-card-subtitle>
        <v-card-text>
          <v-form
            ref="deviceForm"
            v-model="valid"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="4"
                xs="4"
                md="4"
              >
                <v-text-field
                  v-model="hw.name"
                  label="Nazwa sprzętu"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  placeholder="Nazwa sprzętu"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.manufacturer"
                  label="Producent"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  placeholder="Producent"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.model"
                  label="Model"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  outlined
                  dense
                  clearable
                  placeholder="Model"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.serialNo"
                  label="Numer seryjny"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  placeholder="Numer seryjny"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.referenceNo"
                  label="Numer ewidencyjny"
                  outlined
                  dense
                  clearable
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="Numer ewidencyjny"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-autocomplete
                  v-model="hw.categoryId"
                  :items="session.categories"
                  item-value="id"
                  item-text="name"
                  label="Kategoria"
                  no-data-text="Nie znaleziono kategorii"
                  outlined
                  dense
                  clearable
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="Kategoria"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-autocomplete
                  v-model="hw.systemId"
                  :items="session.systems"
                  label="System operacyjny"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  no-data-text="Nie znaleziono systemu"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="System operacyjny"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-autocomplete
                  v-model="hw.employeeId"
                  :items="session.employees"
                  item-text="name"
                  item-value="id"
                  label="Pracownik"
                  no-data-text="Nie znaleziono pracownika"
                  outlined
                  dense
                  clearable
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="Pracownik"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-autocomplete
                  v-model="hw.departmentId"
                  :items="session.departments"
                  no-data-text="Nie znaleziono działu"
                  label="Dział"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="Dział"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.invoiceNumber"
                  label="Numer FV"
                  outlined
                  dense
                  clearable
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  placeholder="Numer FV"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="hw.issueDate"
                      label="Data zakupu"
                      outlined
                      dense
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="hw.issueDate"
                    locale="pl"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.purchaseValue"
                  label="Kwota brutto"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  suffix="zł"
                  type="number"
                  placeholder="Kwota brutto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-autocomplete
                  v-model="hw.warrantyTypeId"
                  :items="session.warrantyTypes"
                  item-text="name"
                  item-value="id"
                  label="Okres gwarancji"
                  outlined
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  no-data-text="Nie znaleziono gwarancji"
                  dense
                  clearable
                  placeholder="Okres gwarancji"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="hw.warrantyStartDate"
                      label="Data rozpoczęcia gwarancji"
                      outlined
                      dense
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="hw.warrantyStartDate"
                    locale="pl"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="4"
                md="4"
                xs="4"
              >
                <v-select
                  v-model="hw.statusId"
                  :items="session.statuses"
                  :menu-props="{ top: true, offsetY: true }"
                  small-chips
                  item-value="id"
                  item-text="name"
                  label="Status"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  no-data-text="Nie znaleziono statusu"
                  outlined
                  dense
                  clearable
                  persistent-hint
                >
                  <template #selection="{ item }">
                    <v-chip
                      small
                      :color="item.color"
                    >
                      {{ item.name }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="hw.categoryId == 10"
                cols="4"
                xs="4"
                md="4"
              >
                <v-text-field
                  v-model="hw.telephone"
                  label="Numer telefonu"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  placeholder="Numer telefonu"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="hw.categoryId == 10"
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.pin"
                  label="Kod PIN"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  clearable
                  placeholder="Kod PIN"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="hw.categoryId == 10"
                cols="4"
                md="4"
                xs="4"
              >
                <v-text-field
                  v-model="hw.puk"
                  label="Kod PUK"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  outlined
                  dense
                  clearable
                  placeholder="Kod PUK"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
                xs="12"
              >
                <v-textarea
                  v-model="hw.comment"
                  label="Komentarz"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
                  outlined
                  dense
                  rows="2"
                  clearable
                  placeholder="Komentarz"
                ></v-textarea>
              </v-col>
              <v-col
                cols="6"
                md="6"
              >
                <v-radio-group
                  v-model="hw.new"
                  dense
                  row
                  style="margin-top: -2px"
                >
                  <template v-slot:label>
                    <span>Stan techniczny:</span>
                  </template>
                  <v-radio
                    label="Nowy"
                    dense
                    :value="true"
                  ></v-radio>
                  <v-radio
                    label="Używany"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="4"
                md="4"
                offset-md="2"
              >
                <v-switch
                  v-model="hw.enroll"
                  style="margin-top: 0"
                  label="ENROLL"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  id="app"
                  class="flex w-full h-screen items-center justify-center text-center"
                >
                  <div
                    class="fileInputDialog d-flex flex-column align-center"
                    style="background-color: rgba(223,201,236,0.32);border-radius: 5px"
                    @dragleave="dragleave"
                    @dragover="dragover"
                    @drop="drop"
                  >
                    <input
                      id="assetsFieldHandle"
                      ref="file"
                      type="file"
                      multiple
                      name="fields[assetsFieldHandle][]"
                      class="w-px h-px opacity-0 overflow-hidden absolute ma-1"
                      @change="onChange"
                    />
                    <label
                      for="assetsFieldHandle"
                      class="block cursor-pointer"
                    >
                      Przeciągnij pliki
                      lub <span class="underline">kliknij tutaj</span> aby dodać pliki
                    </label>
                  </div>
                  <v-row v-if="fileList.length">
                    <v-col cols="12">
                      <ul
                        v-if="fileList.length"
                        class="mt-1"
                      >
                        <li
                          v-for="file in fileList"
                          :key="file"
                          style="list-style-type: none;"
                          class="text-sm"
                        >
                          <span>{{ file.name }}</span>
                          <button
                            title="Remove file"
                            type="button"
                            @click="remove(fileList.indexOf(file))"
                          >
                            <v-btn icon>
                              <v-icon>{{ icons.mdiEye }}</v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon color="error">
                                {{ icons.mdiClose }}
                              </v-icon>
                            </v-btn>
                          </button>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <FileList :files="testFiles"
                            @send="refreshDeviceFiles"></FileList>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                v-if="hw.id"
                color="primary"
                outlined
                disabled
                @click="historyDialog"
              >
                Historia
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="saveDialog"
              >
                Zapisz
              </v-btn>
              <v-btn
                color="error"
                type="reset"
                outlined
                class="mx-2"
                @click="closeDialog"
              >
                Anuluj
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <v-dialog v-model="historyDialogV" width="800">
        <v-card>
          <v-card-title>
            Historia
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="historyDialogV = false" outlined color="error">
              Zamknij
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{ snackbarContent }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
  mdiExportVariant,
  mdiPencilOutline,
  mdiSquareEditOutline,
  mdiLaptop,
  mdiClose,
  mdiEye,
  mdiMagnify, mdiFileCheck, mdiFileCheckOutline
} from '@mdi/js'
import XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import moment from 'moment'
import {mapState} from 'vuex'
import DeviceService from '@/services/DeviceService'
import FileService from '@/services/FileService'
import helpers from '@/utils/helpers'
import FileList from '@/components/FileList.vue'
const fs = require('fs');

import {
  WidthType,
  BorderStyle,
  Document,
  Paragraph,
  Packer,
  TextRun,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImageRun,
  Media,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
} from "docx";

export default {
  name: 'Dashboard',
  components: {FileList,  WidthType,
    BorderStyle,
    Document,
    Paragraph,
    Packer,
    TextRun,
    saveAs},
  data() {
    return {
      helpers,
      summary: {},
      devices: [],
      dtLoading: false,
      valid: true,
      errorMessages: [],
      search: '',
      selectedStatus: [],
      selectedCategory: null,
      dialog: false,
      removeDialog: false,
      deviceToRemove: 'TEST device to remove',
      deviceToRemoveId: null,
      markMany: false,
      childDialog: this.dialog,
      menu: false,
      menu2: false,
      rules: {
        required: value => !!value || 'Pole wymagane'
      },
      departments: [],
      device: null,
      hw: {
        categoryId: 1,
        new: true,
        enroll: false,
        purchaseDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        warrantyStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      dialogTitle: 'Rejestracja sprzętu',
      page: 1,
      selected: [],
      snackbar: false,
      snackbarColor: '',
      snackbarContent: '',
      fileList: [],
      testFiles: [],
      historyDialogV: false,
      protocolDialog: false,
      protocolType: 'onboarding',
      icons: {
        mdiFileCheckOutline,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiLaptop,
        mdiEye,
        mdiClose
      }
    }
  },
  computed: {
    ...mapState([
      'session'
    ])
  },
  beforeMount() {
    if (!this.$store.state.session.loggedIn) {
      this.$router.push('/login').catch(error => {
        console.info(error.message)
      })
    }
  },
  mounted() {
    if (!this.$store.state.session.loggedIn) {
      this.$router.push('/login')
    } else {
      this.refreshDevices()
    }
  },
  methods: {
    generateProtocolDialog(id) {
      this.deviceToGenerateProtocol = id
      this.protocolDialog = true
    },
    confirmGenerateProtocol() {
      this.protocolDialog = false
      this.generateProtocol(this.deviceToGenerateProtocol, this.protocolType)
      this.deviceToGenerateProtocol = null
      this.protocolType = 'onboarding'
    },
    cancelGenerateProtocol() {
      this.protocolDialog = false
      this.deviceToGenerateProtocol = null
      this.protocolType = 'onboarding'
    },
    generateProtocol(id, type) {
      window.location.assign(`http://localhost:8000/api/v1/files/${id}/protocol/${type}`)
    },
    saveDocumentToFile: function (doc, fileName) {
      const mimeType =
        "application/vnd.openxmlformatsofficedocument.wordprocessingml.document";
      Packer.toBlob(doc).then((blob) => {
        const docblob = blob.slice(0, blob.size, mimeType);
        saveAs(docblob, fileName);
      });
    },
    resetFileInput () {
      if (this.$refs.file) {
        document.getElementById('assetsFieldHandle').value = ''
        this.fileList = []
      }
    },
    refreshDevices(filter) {
      this.device = null
      this.resetFileInput()
      if (!filter) {
        DeviceService.getDevices()
          .then(response => {
            this.devices = (response.data).data
            for (const device of this.devices) {
              if (!this.summary[device.category]) {
                this.summary[device.category] = 1
              } else {
                this.summary[device.category] += 1
              }
            }
            console.log(this.summary)
            this.dtLoading = false
          })
          .catch(() => ({
            name: 'NetworkError'
          }))
          .finally(() => {
          })
      } else {
        DeviceService.getDevicesFiltered(filter)
          .then(response => {
            this.devices = (response.data).data
            this.dtLoading = false
          })
          .catch(() => ({
            name: 'NetworkError'
          }))
          .finally(() => {
          })
      }
    },
    setSnackBar(snackbarColor, snackbarContent) {
      this.snackbar = true
      this.snackbarColor = snackbarColor
      this.snackbarContent = snackbarContent
    },
    historyDialog() {
      this.historyDialogV = true
    },
    confirmRemove() {
      DeviceService.deleteDevice(this.deviceToRemoveId).then(response => {
        if (response.data.status === 'success') {
          this.setSnackBar('success', 'Sprzęt usunięty')
        }
      }).catch(response => {
        console.log(response.data)
        this.setSnackBar('error', 'Błąd zapisu danych')
      })
      this.refreshDevices()
      this.removeDialog = false
      this.deviceToRemove = ''
      this.deviceToRemoveId = null
    },
    cancelRemove() {
      this.removeDialog = false
      this.deviceToRemove = ''
    },
    saveDialog() {
      if (this.hw.id) {
        DeviceService.updateDevice(this.hw).then(response => {
          if (response.data.status === 'success') {
            this.setSnackBar('success', 'Zmiany zostały zapisane')
          }
        }).catch(response => {
          console.log(response.data)
          this.setSnackBar('error', 'Błąd zapisu danych')
        })
      } else {
        this.hw.issuerId = this.session.userSession.user.id
        this.hw.creationDate = new Date()
        DeviceService.addDevice(this.hw).then(response => {
          if (response.data.status === 'success') {
            this.device = response.data.data[0].id
            if (this.$refs.file.files[0]) {
              FileService.uploadFile(this.$refs.file.files[0], this.device).then(res => {
                console.log(res)
                this.resetFileInput()
                this.device = null
              }).catch(error => {
                console.info(error.message)
              })
            }
            this.setSnackBar('success', 'Zmiany zostały zapisane')
          }
        }).catch(response => {
          console.log(response.data)
          this.setSnackBar('error', 'Błąd zapisu danych')
        })
      }
      this.hw = {}
      this.dialog = false
      setTimeout(() => {
        this.dtLoading = true
        this.refreshDevices()
      }, 1000)
    },
    closeDialog() {
      this.hw = {
        new: true,
        enroll: false,
        purchaseDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        warrantyStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      }
      this.errorMessages = []
      this.fileList = []
      this.dialog = false
      this.refreshDevices()
      this.setSnackBar('warning', 'Zmiany zostały porzucone')
    },
    datePickerFormat(date) {
      return (new Date(date).toISOString().substr(0, 10))
    },
    refreshDeviceFiles(id) {
      FileService.getDeviceFiles(id).then(response => {
        this.testFiles = response.data.data
      })
    },
    removeItem(id) {
      DeviceService.getDevice(id).then(response => {
        let device = (response.data).data[0]
        this.deviceToRemoveId = device.id
        this.deviceToRemove  = device.name + ' numer seryjny: ' + device.serialNo + '.'
        this.removeDialog = true
      })
    },
    editItem(id) {
      this.fileList = []
      if (id) {
        this.device = id
        DeviceService.getDevice(id).then(response => {
          [this.hw] = (response.data).data
          this.hw.warrantyStartDate = this.datePickerFormat(this.hw.warrantyStartDate)
          if (this.hw.purchaseDate) {
            this.hw.purchaseDate = this.datePickerFormat(this.hw.purchaseDate)
          }
          if (this.hw.issueDate) {
            this.hw.issueDate = this.datePickerFormat(this.hw.issueDate)
          }
          this.dialog = true
        })
          .catch(() => ({
            name: 'NetworkError'
          }))
          .finally(() => {
          })
        this.refreshDeviceFiles(id)
      } else {
        this.device = null
        this.hw = {
          new: true,
          enroll: false,
          issueDate: this.datePickerFormat(new Date()),
          warrantyStartDate: this.datePickerFormat(new Date()),
          purchaseDate: this.datePickerFormat(new Date())
        }
        this.dialog = true
      }
    },
    dateformat(v) {
      return moment(v).format('DD-MM-YYYY')
    },
    filterDevices() {
      this.dtLoading = true
      this.refreshDevices({statusId: this.selectedStatus, categoryId: this.selectedCategory})
    },
    exportHardware() {
      const wb = XLSX.utils.book_new()
      wb.Props = {
        Title: 'Raport sprzętu',
        Subject: 'Raport sprzętu',
        Author: 'Smartney Hardware Repository'
      }
      const selectedDevices = this.selected
      const wsData = [['Model', 'Numer seryjny', 'Przyjmujący', 'Wydający', 'Data', 'Status']]
      selectedDevices.forEach(j => {
        wsData.push([j.name, j.serialNo, j.recipient, j.issuer, j.issueDate, j.status])
      })
      const ws = XLSX.utils.aoa_to_sheet(wsData)
      ws['!cols'] = [{wch: 30}, {wch: 25}, {wch: 30}, {wch: 25}, {wch: 30}, {wch: 25}]
      wb.Sheets.Raport = ws
      wb.SheetNames.push('Raport')
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) /* & 0xFF */

        return buf
      }
      saveAs(new Blob([s2ab(XLSX.write(wb, {bookType: 'xlsx', type: 'binary'}))], {type: 'application/octet-stream'}), 'Eksport.xlsx')
      this.selected = []
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      if (this.device) {
        FileService.uploadFile(this.$refs.file.files[0], this.device).then(response => {
          console.log(response)
        }).catch(error => {
          console.info(error.message)
        })
      }
    },
    remove(i) {
      this.fileList.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()

      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    }
  }
}
</script>
