import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ts_tok',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getDeviceFiles(id) {
    return apiClient.get(`/files/device/${id}`)
  },
  markFileToDelete(id) {
    return apiClient.delete(`/files/${id}`)
  },
  uploadFileCC(file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.put(`${baseURL}/api/v1/files/cc`, formData)
  },
  uploadFile(file, deviceId) {
    const formData = new FormData();
    formData.append("file", file);
    axios.post(`${baseURL}/api/v1/files/${deviceId}`, formData)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
  }
}
