<template>
  <ul
    v-if="files.length"
    class="mt-1"
  >
    <li
      v-for="file in files"
      :key="file.id"
      style="list-style-type: none;"
      class="text-sm"
    >
      <span>{{file.filename}}</span>
      <button
        title="Remove file"
        type="button"
      >
        <v-btn icon @click="downloadFile(file.id)"><v-icon>{{icons.mdiEye}}</v-icon></v-btn>
        <v-btn icon @click="deleteFile(file.id)"><v-icon color="error">{{icons.mdiClose}}</v-icon></v-btn>
      </button>
    </li>
  </ul>
</template>

<script>
import {
  mdiClose,
  mdiEye
} from '@mdi/js'
import FileService from "@/services/FileService";

export default {
  name: "FileList",
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiClose,
        mdiEye
      }
    }
  },
  methods: {
    downloadFile(id) {
      window.location.assign('http://localhost:8000/api/v1/files/' + id + '/download')
    },
    deleteFile(id) {
      FileService.markFileToDelete(id).then((response) => {
        this.$emit('send', response.data.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
